export default [
  {
    key: "employeeId",
    label: "field.employee",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "nameLabel",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "invoiceCode",
    label: "field.invoiceCode",
    type: "text",
  },
  {
    key: "isCancel",
    label: "field.cancel",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.yes", value: "1" },
      { text: "field.no", value: "0" },
    ],
    lg: "auto",
  },
];
