export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "invoiceCode",
    sortable: true,
    sortField: "invoiceCode",
    label: "field.invoiceCode",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "employeeName",
    label: "field.employeeName",
  },
  {
    key: "employeeCode",
    label: "field.idCard",
  },
  {
    key: "paymentType",
    label: "field.paymentType",
  },
  {
    key: "total",
    label: "field.total",
  },
  {
    key: "totalItem",
    label: "field.totalItem",
  },
  {
    key: "isCancel",
    label: "field.isCancel",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "createdAt",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
